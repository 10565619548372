import React, { useState, useCallback } from 'react';
import { FaPlus, FaSearch, FaEdit, FaTrash, FaDownload, FaFile, FaFilePdf, FaFileExcel, FaFileWord, FaUpload, FaTimes } from 'react-icons/fa';

const Forms = ({ forms, addForm, editForm, deleteForm }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [newForm, setNewForm] = useState({ title: '', file: null });
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [errors, setErrors] = useState({});

  const filteredForms = forms
    .filter(form =>
      form.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.title.localeCompare(b.title)); // Ordena alfabeticamente por título

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewForm({
        ...newForm,
        file: file
      });
    }
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'application/pdf':
        return <FaFilePdf className="text-red-500" />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <FaFileExcel className="text-green-500" />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <FaFileWord className="text-blue-500" />;
      default:
        return <FaFile className="text-gray-500" />;
    }
  };

  const handleAddForm = async () => {
    const newErrors = {};
    if (!newForm.title.trim()) newErrors.title = "Título é obrigatório";
    if (!newForm.file) newErrors.file = "Arquivo é obrigatório";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await addForm(newForm);
      setNewForm({ title: '', file: null });
      setShowAddForm(false);
      setErrors({});
    } catch (error) {
      console.error("Error adding form:", error);
      setErrors({ submit: "Falha ao adicionar formulário. Por favor, tente novamente." });
    }
  };

  const handleDeleteForm = (id, title) => {
    setConfirmDelete({ id, title });
  };

  const confirmDeleteForm = async () => {
    if (confirmDelete) {
      try {
        console.log("Attempting to delete form:", confirmDelete.id);
        const success = await deleteForm(confirmDelete.id);
        if (success) {
          console.log("Form deleted successfully");
          setConfirmDelete(null);
          setError(null);
        } else {
          throw new Error("Failed to delete form");
        }
      } catch (error) {
        console.error("Error deleting form:", error);
        setError("Failed to delete form. The document was removed, but the file may still exist in storage.");
      }
    }
  };

  const handleDownload = (form) => {
    window.open(form.storageUrl, '_blank');
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      setNewForm({
        ...newForm,
        file: file
      });
    }
  }, [newForm]);

  const handleRemoveFile = () => {
    setNewForm({
      ...newForm,
      file: null
    });
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div className="bg-gray-100 min-h-screen p-2 sm:p-6">
      <div className="max-w-6xl mx-auto"> {/* Alterado de max-w-3xl para max-w-6xl */}
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-700">Formulários</h2>
            <button 
              onClick={() => setShowAddForm(!showAddForm)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 flex items-center"
            >
              <FaPlus className="mr-2" />
              Adicionar
            </button>
          </div>
          
          {showAddForm && (
            <div className="p-6 bg-white border-b border-gray-200">
              <h3 className="text-lg font-medium text-gray-700 mb-4">Novo Formulário</h3>
              <div className="space-y-4">
                <div>
                  <label htmlFor="form-title" className="block text-sm font-medium text-gray-700 mb-1">
                    Título <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="form-title"
                    type="text"
                    value={newForm.title}
                    onChange={(e) => setNewForm({ ...newForm, title: e.target.value })}
                    placeholder="Título do formulário"
                    className={`w-full p-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    required
                  />
                  {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title}</p>}
                </div>
                <div>
                  <label htmlFor="dropzone-file" className="block text-sm font-medium text-gray-700 mb-1">
                    Arquivo <span className="text-red-500">*</span>
                  </label>
                  {!newForm.file && (
                    <div 
                      className={`flex items-center justify-center w-full ${
                        isDragging ? 'border-blue-500 bg-blue-100' : 'border-gray-300 bg-gray-50'
                      } border-2 border-dashed rounded-lg cursor-pointer hover:bg-gray-100`}
                      onDragEnter={handleDragEnter}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <FaUpload className="w-10 h-10 mb-3 text-gray-400" />
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">Clique para fazer upload</span> ou arraste e solte
                          </p>
                          <p className="text-xs text-gray-500">PDF, XLSX, DOCX (MAX. 10MB)</p>
                        </div>
                        <input 
                          id="dropzone-file" 
                          type="file" 
                          className="hidden" 
                          onChange={handleFileChange} 
                          accept=".pdf,.xlsx,.docx" 
                          required
                        />
                      </label>
                    </div>
                  )}
                  {newForm.file && (
                    <div className="flex items-center justify-between p-4 bg-gray-100 rounded-md">
                      <div className="flex items-center">
                        {getFileIcon(newForm.file.type)}
                        <span className="ml-2">{newForm.file.name}</span>
                      </div>
                      <button 
                        onClick={handleRemoveFile}
                        className="text-red-600 hover:text-red-800 transition duration-300"
                        title="Remover arquivo"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  )}
                  {errors.file && <p className="text-red-500 text-xs mt-1">{errors.file}</p>}
                </div>
                <button 
                  onClick={handleAddForm}
                  className="w-full bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition duration-300"
                >
                  Adicionar Formulário
                </button>
                {errors.submit && <p className="text-red-500 text-xs mt-1">{errors.submit}</p>}
              </div>
            </div>
          )}
          
          <div className="mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Pesquisar formulários..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 pl-8 pr-8 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              {searchQuery && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <FaTimes />
                </button>
              )}
            </div>
          </div>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
              <strong className="font-bold">Erro!</strong>
              <span className="block sm:inline"> {error}</span>
            </div>
          )}

          <div className="space-y-4">
            {filteredForms.map((form) => (
              <div key={form.id} className="bg-white p-3 sm:p-4 rounded shadow-sm border border-gray-200">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                  <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-auto overflow-hidden">
                    {getFileIcon(form.type)}
                    <h3 className="font-semibold text-sm text-gray-800 ml-2 truncate flex-1 min-w-0">
                      {form.title}
                    </h3>
                  </div>
                  <div className="border-t border-gray-200 my-2 sm:hidden"></div>
                  <div className="flex space-x-2 justify-center w-full sm:w-auto">
                    <button
                      onClick={() => {
                        const newTitle = prompt('Novo título:', form.title);
                        if (newTitle !== null && newTitle !== form.title) {
                          editForm(form.id, { ...form, title: newTitle });
                        }
                      }}
                      className="text-yellow-500 hover:text-yellow-700 p-2"
                      title="Editar"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteForm(form.id, form.title)}
                      className="text-red-500 hover:text-red-700 p-2"
                      title="Excluir"
                    >
                      <FaTrash />
                    </button>
                    <button
                      onClick={() => handleDownload(form)}
                      className="text-green-500 hover:text-green-700 p-2"
                      title="Download"
                    >
                      <FaDownload />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar exclusão</h3>
            <p className="mb-4">Tem certeza de que deseja excluir o formulário "{confirmDelete.title}"?</p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
              <button
                onClick={() => setConfirmDelete(null)}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                Cancelar
              </button>
              <button
                onClick={confirmDeleteForm}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Forms;