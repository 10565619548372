import React, { useState, useEffect } from 'react';
import { FaTrash, FaUserPlus, FaTimes, FaPlus } from 'react-icons/fa';
import { collection, query, onSnapshot, updateDoc, doc, serverTimestamp, addDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase/config';

const Viagens = () => {
  const [users, setUsers] = useState([]);
  const [newUserName, setNewUserName] = useState('');
  const [showAddUser, setShowAddUser] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const q = query(collection(db, 'users'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const usersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        trips: doc.data().trips || 0,
      }));
      setUsers(usersData);
    });
    return () => unsubscribe();
  }, []);

  const addActivity = async (action, description) => {
    await addDoc(collection(db, "activities"), {
      type: 'viagem',
      action,
      description,
      timestamp: serverTimestamp(),
      userId: auth.currentUser.uid,
      userName: auth.currentUser.displayName || auth.currentUser.email
    });
  };

  const handleUpdateTrips = async (userId, newTrips) => {
    const userRef = doc(db, 'users', userId);
    const user = users.find(u => u.id === userId);
    await updateDoc(userRef, {
      trips: newTrips,
      lastUpdated: serverTimestamp(),
      updatedBy: auth.currentUser.displayName || auth.currentUser.email
    });
    addActivity('edit', `Viagens atualizadas: ${user.displayName} - ${newTrips} viagens`);
  };

  const handleRemoveUserFromTrips = async (userId) => {
    if (window.confirm("Tem certeza que deseja excluir este usuário da tabela de viagens?")) {
      const userRef = doc(db, 'users', userId);
      const user = users.find(u => u.id === userId);
      try {
        await deleteDoc(userRef);
        addActivity('delete', `Usuário removido da tabela de viagens: ${user.displayName}`);
        // Atualizar o estado local para refletir a remoção
        setUsers(users.filter(u => u.id !== userId));
      } catch (error) {
        console.error("Erro ao excluir usuário:", error);
        alert("Houve um erro ao excluir o usuário. Por favor, tente novamente.");
      }
    }
  };

  const handleAddUser = async () => {
    if (newUserName.trim()) {
      try {
        const docRef = await addDoc(collection(db, 'users'), {
          displayName: newUserName.trim(),
          trips: 0,
          lastUpdated: serverTimestamp(),
          updatedBy: auth.currentUser.displayName || auth.currentUser.email
        });
        addActivity('add', `Novo usuário adicionado à tabela de viagens: ${newUserName.trim()}`);
        setNewUserName('');
        setShowAddUser(false);
        setError(null);
      } catch (error) {
        console.error("Erro ao adicionar usuário:", error);
        setError("Houve um erro ao adicionar o usuário. Por favor, tente novamente.");
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-700">Viagens</h2>
            <button 
              onClick={() => setShowAddUser(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 flex items-center"
            >
              <FaPlus className="mr-2" />
              Utilizador
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Viagens</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Última Modificação</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modificado Por</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{user.displayName || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <select
                        value={user.trips}
                        onChange={(e) => handleUpdateTrips(user.id, parseInt(e.target.value))}
                        className="border rounded px-2 py-1"
                      >
                        {[...Array(11)].map((_, i) => (
                          <option key={i} value={i}>{i}</option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user.lastUpdated ? new Date(user.lastUpdated.toDate()).toLocaleString() : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{user.updatedBy || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleRemoveUserFromTrips(user.id)}
                        className="text-red-600 hover:text-red-900"
                        title="Excluir da tabela de viagens"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Pop-up para adicionar novo usuário */}
      {showAddUser && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Adicionar Novo Utilizador</h3>
              <button onClick={() => setShowAddUser(false)} className="text-gray-500 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>
            <div className="mb-4">
              <label htmlFor="newUserName" className="block text-sm font-medium text-gray-700 mb-1">
                Nome do Utilizador
              </label>
              <input
                type="text"
                id="newUserName"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Digite o nome do utilizador"
              />
            </div>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <button
              onClick={handleAddUser}
              className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 flex items-center justify-center"
            >
              <FaPlus className="mr-2" />
              Adicionar Utilizador
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Viagens;