import React, { useState, useEffect } from 'react';
import { updateProfile } from 'firebase/auth';
import { auth, db } from '../../firebase/config';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { FaUser, FaSave } from 'react-icons/fa';

const decodeUnicode = (str) => {
  return str.replace(/\\u[\dA-F]{4}/gi, 
    function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
};

const MyAccount = ({ user, onUpdateProfile }) => {
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDisplayName(user.displayName || '');
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsLoading(true);

    try {
      await updateProfile(auth.currentUser, {
        displayName: displayName,
      });

      // Update activities with the new display name
      const activitiesRef = collection(db, "activities");
      const q = query(activitiesRef, where("userId", "==", user.uid));
      const querySnapshot = await getDocs(q);
      
      const updatePromises = querySnapshot.docs.map(doc => 
        updateDoc(doc.ref, { userName: displayName })
      );
      
      await Promise.all(updatePromises);

      setSuccess('Cão bem mandado'); // Alterado aqui
      if (onUpdateProfile) {
        onUpdateProfile({ displayName });
      }
    } catch (error) {
      console.error('Erro ao atualizar o nome:', error);
      setError('Erro ao atualizar o nome. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const buttonText = isLoading ? 'Atualizando...' : decodeUnicode('Salvar altera\u00E7\u00F5es');

  return (
    <div className="flex items-center justify-center min-h-full p-4 sm:p-0">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Minha Conta</h2>
        <div className="mb-8 text-center">
          <div className="inline-block p-2 rounded-full bg-blue-100">
            <FaUser className="text-4xl text-blue-600" />
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="displayName" className="block text-sm font-medium text-gray-700 mb-1">
              Atrasado muda o nome
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaUser className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                id="displayName"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Seu nome"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out`}
            >
              {isLoading ? (
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <FaSave className="mr-2" />
              )}
              {buttonText}
            </button>
          </div>
        </form>
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        {success && <p className="mt-2 text-sm text-green-600">{success}</p>}
      </div>
    </div>
  );
};

export default MyAccount;