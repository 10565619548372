import React, { useState, useEffect } from 'react';
import { FaPlus, FaSearch, FaEye, FaEdit, FaTrash, FaCopy, FaSave, FaTimes } from 'react-icons/fa';

const Texts = ({ texts, addText, editText, deleteText, copyToClipboard }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddText, setShowAddText] = useState(false);
  const [newText, setNewText] = useState({ title: '', content: '' });
  const [editingText, setEditingText] = useState(null);
  const [selectedText, setSelectedText] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [copyNotification, setCopyNotification] = useState(false);
  const [errors, setErrors] = useState({});

  // Ordenar os textos alfabeticamente por título
  const sortedTexts = [...texts].sort((a, b) => 
    a.title.localeCompare(b.title, 'pt-BR', { sensitivity: 'base' })
  );

  const filteredTexts = sortedTexts.filter(text =>
    text.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddText = () => {
    const newErrors = {};
    if (!newText.title.trim()) newErrors.title = "Título é obrigatório";
    if (!newText.content.trim()) newErrors.content = "Conteúdo é obrigatório";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    addText(newText);
    setNewText({ title: '', content: '' });
    setShowAddText(false);
    setErrors({});
  };

  const startEditingText = (text) => {
    setEditingText({ ...text });
  };

  const cancelEditingText = () => {
    setEditingText(null);
  };

  const saveEditedText = () => {
    const newErrors = {};
    if (!editingText.title.trim()) newErrors.title = "Título é obrigatório";
    if (!editingText.content.trim()) newErrors.content = "Conteúdo é obrigatório";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    editText(editingText.id, editingText);
    setEditingText(null);
    setErrors({});
  };

  const handleDeleteText = (id, title) => {
    setConfirmDelete({ id, title });
  };

  const confirmDeleteText = () => {
    if (confirmDelete) {
      deleteText(confirmDelete.id);
      setConfirmDelete(null);
    }
  };

  const handleCopyToClipboard = async (content) => {
    await copyToClipboard(content);
    setCopyNotification(true);
    setTimeout(() => setCopyNotification(false), 2000);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-700">Textos</h2>
            <button 
              onClick={() => setShowAddText(!showAddText)}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 flex items-center"
            >
              <FaPlus className="mr-2" />
              Adicionar
            </button>
          </div>

          {showAddText && (
            <div className="mb-4 bg-white p-4 rounded shadow">
              <div className="mb-4">
                <label htmlFor="text-title" className="block text-sm font-medium text-gray-700 mb-1">
                  Título <span className="text-red-500">*</span>
                </label>
                <input
                  id="text-title"
                  type="text"
                  value={newText.title}
                  onChange={(e) => setNewText({ ...newText, title: e.target.value })}
                  placeholder="Digite o título do texto"
                  className={`w-full p-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-400`}
                  required
                />
                {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="text-content" className="block text-sm font-medium text-gray-700 mb-1">
                  Conteúdo <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="text-content"
                  value={newText.content}
                  onChange={(e) => setNewText({ ...newText, content: e.target.value })}
                  placeholder="Digite o conteúdo do texto"
                  className={`w-full p-2 border ${errors.content ? 'border-red-500' : 'border-gray-300'} rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-400`}
                  rows="4"
                  required
                ></textarea>
                {errors.content && <p className="text-red-500 text-xs mt-1">{errors.content}</p>}
              </div>
              <button 
                onClick={handleAddText}
                className="w-full bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
              >
                Adicionar Texto
              </button>
            </div>
          )}

          <div className="mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Pesquisar textos..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 pl-8 pr-8 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              {searchQuery && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <FaTimes />
                </button>
              )}
            </div>
          </div>

          <ul className="space-y-4">
            {filteredTexts.map((text) => (
              <li key={text.id} className="bg-white p-4 rounded shadow-sm border border-gray-200">
                {editingText && editingText.id === text.id ? (
                  <div>
                    <input
                      type="text"
                      value={editingText.title}
                      onChange={(e) => setEditingText({ ...editingText, title: e.target.value })}
                      className={`w-full p-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-400`}
                    />
                    {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title}</p>}
                    <textarea
                      value={editingText.content}
                      onChange={(e) => setEditingText({ ...editingText, content: e.target.value })}
                      className={`w-full p-2 border ${errors.content ? 'border-red-500' : 'border-gray-300'} rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-400`}
                      rows="12"
                    ></textarea>
                    {errors.content && <p className="text-red-500 text-xs mt-1">{errors.content}</p>}
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={saveEditedText}
                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition duration-300"
                      >
                        <FaSave className="inline-block mr-1" /> Salvar
                      </button>
                      <button
                        onClick={cancelEditingText}
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-300"
                      >
                        <FaTimes className="inline-block mr-1" /> Cancelar
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                      <h3 className="font-bold text-lg text-gray-800">{text.title}</h3>
                      <div className="hidden md:flex space-x-2">
                        <button
                          onClick={() => setSelectedText(text)}
                          className="text-blue-500 hover:text-blue-700 p-2"
                          title="Visualizar"
                        >
                          <FaEye />
                        </button>
                        <button
                          onClick={() => startEditingText(text)}
                          className="text-yellow-500 hover:text-yellow-700 p-2"
                          title="Editar"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleDeleteText(text.id, text.title)}
                          className="text-red-500 hover:text-red-700 p-2"
                          title="Excluir"
                        >
                          <FaTrash />
                        </button>
                        <button
                          onClick={() => handleCopyToClipboard(text.content)}
                          className="text-green-500 hover:text-green-700 p-2"
                          title="Copiar para Clipboard"
                        >
                          <FaCopy />
                        </button>
                      </div>
                    </div>
                    <p className="text-gray-600 text-sm mb-2">{text.content.substring(0, 100)}...</p>
                    <div className="md:hidden border-t pt-2 mt-2">
                      <div className="flex justify-center space-x-4">
                        <button
                          onClick={() => setSelectedText(text)}
                          className="text-blue-500 hover:text-blue-700 p-2"
                          title="Visualizar"
                        >
                          <FaEye />
                        </button>
                        <button
                          onClick={() => startEditingText(text)}
                          className="text-yellow-500 hover:text-yellow-700 p-2"
                          title="Editar"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleDeleteText(text.id, text.title)}
                          className="text-red-500 hover:text-red-700 p-2"
                          title="Excluir"
                        >
                          <FaTrash />
                        </button>
                        <button
                          onClick={() => handleCopyToClipboard(text.content)}
                          className="text-green-500 hover:text-green-700 p-2"
                          title="Copiar para Clipboard"
                        >
                          <FaCopy />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {selectedText && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col">
            <div className="p-6 overflow-y-auto flex-grow">
              <h2 className="text-3xl font-bold mb-6 text-gray-800 border-b pb-2">{selectedText.title}</h2>
              <div className="bg-gray-100 p-6 rounded-md shadow-inner">
                <p className="text-gray-700 leading-relaxed text-lg whitespace-pre-wrap">{selectedText.content}</p>
              </div>
            </div>
            <div className="bg-gray-100 px-6 py-4 rounded-b-lg flex flex-col sm:flex-row justify-between items-center">
              <button
                onClick={() => setSelectedText(null)}
                className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600 transition duration-300 mb-2 sm:mb-0 w-full sm:w-auto"
              >
                Fechar
              </button>
              <button 
                onClick={() => handleCopyToClipboard(selectedText.content)}
                className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition duration-300 w-full sm:w-auto"
              >
                <FaCopy className="inline-block mr-2" /> Copiar para Clipboard
              </button>
            </div>
          </div>
        </div>
      )}

      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded-lg shadow-xl max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar exclusão</h3>
            <p className="mb-4">Tem certeza de que deseja excluir o texto "{confirmDelete.title}"?</p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
              <button
                onClick={() => setConfirmDelete(null)}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                Cancelar
              </button>
              <button
                onClick={confirmDeleteText}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {copyNotification && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg max-w-xs w-full mx-4">
          Texto copiado para a área de transferência!
        </div>
      )}
    </div>
  );
};

export default Texts;
